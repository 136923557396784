.homemain {
  padding: 20px;

  height: 100vh;
  background-position: center center;
  background-color: #1b052d;
  overflow: clip;
}
.navbar {
  background-color: #1f0238;
  border: solid 1px #7015c9;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navleft {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: row;
  font-weight: 800;
  text-align: center;
  gap: 8px;
}
.navleft img {
  height: 20px;
}
.navleft p {
  display: inline;
}
.colorChange {
  color: #6b12bf;
  font-size: 17px;
}

.navright {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 40px;
}
.navright ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 40px;
}
.navright ul li {
  list-style-type: none;
  cursor: pointer;
  position: relative;
  opacity: 1;
  transition: all 0.5s ease;
  transform: translateY(0);
}

.navright ul li:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: -1;
}

.navright ul li:hover {
  color: #771bc9;
}

.navrightmobile {
  display: none;
}
.contactbtn {
  background-color: #771bbe;
  padding: 15px 20px;
  border: none;
  border-radius: 15px;
  transition: all 0.5s ease;
  font-weight: 800;
}
.contactbtn:hover {
  background-color: #3a0a66;
  color: #ffff;
}
.homecenter {
  height: calc(90vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homecenter h1 {
  font-weight: 600;

  font-size: 40px;
}
.homecenter h1 u {
  text-decoration-color: #ffc100;
}
.colorYellow {
  color: #ffc100;
  font-weight: 800;
}
.homecenter p {
  width: 60%;
  font-size: 20px;
  margin: 30px 0;
}
.homecenterbutton {
  background-color: #771bc8;
  padding: 20px 30px;
  border: none;
  font-weight: 400;
  transition: all 0.8s ease-in-out;
  border-radius: 50px;
}
.homecenter button:hover {
  background-color: #3a0a66;
  color: #ffff;
  font-weight: 800;
}
.homebottom {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
  align-items: center;
}
.homebutton1 {
  background: none;
  padding: 15px 30px;
  border-radius: 50px;
  transition: all 0.8s ease-in-out;
  border: 2px solid #54039d;
}
.homebutton2 {
  background-color: #360a60;
  border-radius: 100%;
  border: none;
  padding: 20px;
  display: flex;
  transition: all 0.8s ease-in-out;
  justify-content: center;
  align-items: center;
}
.homebutton1:hover {
  background-color: #3a0a66;
  color: #ffff;
}
.homebutton2:hover {
  background-color: #771bc9;
  color: #ffff;
}
.homebutton2 img {
  height: 15px;
  width: 15px;
}
.homebottommobile {
  display: none;
}
.mobileonly {
  display: none;
}

@media screen and (max-width: 1000px) {
  .homemain {
    background-image: none;
  }
  .navright {
    display: none;
  }
  .homecenter h1 {
    font-size: 24px;
  }
  .homecenter p {
    width: 80%;
    font-size: 16px;
  }
  .navrightmobile {
    display: block;
  }
  .navbar {
    border: none;
    background-color: transparent;
  }
  .homebottom {
    display: none;
  }
  .homebottommobile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .homebutton1 {
    width: 210px;
    padding: 20px 30px;
    height: 70px;
    margin-top: 20px;
  }

  .homecenterbutton {
    width: 210px;
    height: 70px;
  }
  .mobileonly {
    display: inline;
  }
  .desktoponly {
    display: none;
  }
  .homecenter {
    height: 95vh;
  }
}
