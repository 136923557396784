.showcase {
  background-image: url(../assests/Lines.png);
  background-color: #111;
  padding-top: 40px;
}

.showcasemain {
  width: 100%;
  margin: 100px auto;
  padding-bottom: 100px;
  display: flex;
  font-family: "Inter", sans-serif;
  justify-content: center;
  overflow-y: clip;
}

@keyframes fade-in {
  from {
    opacity: 0.3;
    transform: scale(0.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes contentfade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}
.showcasecenter,
.showcaseright {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.showcaseright {
  justify-self: center;
  align-items: center;
  margin-inline: 20px;
}
.showcaseright a {
  height: 100%;
}
.showcasecenter a:hover {
  text-decoration: underline;
}
.showcaseright img {
  height: 100%;
  animation-timeline: view();
  animation-range-start: 300px;
  animation-range-end: 550px;

  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: 100ms;
  animation-timing-function: linear;
}

.showcasecenter {
  text-align: left;
  margin-left: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: flex-start;
}
.showcaseleft {
  padding-top: 40px;
  padding-bottom: 40px;
}
.showcasecentercontent {
  width: 90%;
  animation-timeline: view();
  animation-range-start: 300px;
  animation-range-end: 550px;

  animation-name: contentfade;
  animation-fill-mode: both;
  animation-duration: 100ms;
}
.showcaseright {
  justify-content: center;
}
.showcasecenter h1 {
  font-size: 36px;
  margin-top: 0px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 10px;
  animation-timeline: view();
  animation-range-start: 300px;
  animation-range-end: 550px;

  animation-name: contentfade;
  animation-fill-mode: both;
}
.showcaseleft {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 30px;
  overflow: visible;
  position: relative;
  animation-timeline: view();
  animation-range-start: 300px;
  animation-range-end: 550px;

  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: 100ms;
}
.showcaseline {
  width: 2px;
  height: calc(100% - 84px);
  background-color: #ffffff;
  position: absolute;
  overflow: clip;
  top: 40px;
}
.showcasecircle {
  position: absolute;
  bottom: 37px;
  left: -4px;
  animation-timeline: view();
  animation-range-start: 300px;
  animation-range-end: 550px;

  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: 100ms;
}

.showcasecenterspeaker {
  margin-top: 20px;
}

.showcasecentercontent {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  font-size: 20px;
  color: #a29e9a;
}
.yellowshowcase {
  color: #e1ab20;
  display: inline;
}
.showcasemobile {
  width: 100vw;
}
.showcasemobile .showcasemain {
  width: 80%;
  padding: 20px;
  display: flex;
  justify-content: center;
}
.showcasemobile .showcasecenter {
  width: 80%;
  display: flex;
  justify-content: center;
}
.showcasemobile .showcasecenter,
.showcasemobile .showcaseright {
  width: 100%;
}
.showcasemobile .showcasecentercontent {
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .showcasedesktop {
    display: none;
  }
  .showcasecenter h1 {
    font-size: 20px;
    margin-top: 11px;
    margin-bottom: 10px;
  }
  .showcasecentercontent p {
    font-size: 14px;
    margin-top: 20px;
  }
  .showcaseright {
    margin-inline: 0px;
  }
  .showcaseright img {
    height: 100%;
    max-height: 200px;
  }
}
@media screen and (min-width: 1001px) {
  .showcasemobile {
    display: none;
  }
}
