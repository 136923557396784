@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800&display=swap");

.footerworklefthead h1 {
  display: inline;
  color: #6b12bf;
  font-size: 20px;
  font-weight: 600x;
}
.footerworklefthead .whitecolor {
  color: #fff;
  font-size: 20px;
}
.footerwork {
  margin-top: -5px;
  background: linear-gradient(180deg, #000 0%, #1d1e20 100%);
  color: #fff;
  font-family: "Open Sans", sans-serif;
  padding: 40px 0px 40px 20px;
  display: flex;

  justify-content: space-between;
}
.footerworklefthead {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 10px;
  color: #fc17a0;
  gap: 5px;
}
.footerworkleftbody {
  margin-top: 20px;
  text-align: left;
}
.footerworklefthead img {
  height: 20px;
}
.footerworkleftside h1 {
  font-size: 20px;
  text-align: center;
  color: #771bc8;
  margin: 10px 0 10px 0;
}
.footerworkright {
  display: flex;

  width: 70%;
  justify-content: space-evenly;
  align-items: flex-start;
}
.footerworkleft {
  width: 30%;
  padding: 10px;
}
.footerworkrightbody {
  width: 32%;
  padding: 10px;
  font-size: 15px;
}
.footerworkrightheader {
  color: #771bc8;
  font-size: 14px;
  text-align: left;
}
.footerworkrightsplitter {
  width: 50%;
  border: 1px solid #771bc8;
  margin: 10px 0 20px 0;
}
.footerwork ul {
  list-style-type: none;
}
.footerworkrightbody a {
  color: #fff;
  text-decoration: none;
}
.footerwork ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.5s ease;
  margin-bottom: 15px;
}
.footerwork ul li:hover {
  color: #771bc9;
}
.footerwork ul li img {
  width: 18px;
}
.socials {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px auto 20px auto;
}
.socials img {
  width: 30px;
}
.footer {
  padding: 20px;
  background-color: #390e62;
}
form {
  width: fit-content;
}
.formmain {
  display: flex;
  flex-direction: row;
}
.footerworkrightbody input {
  background: none;
}
.footerworkrightbody input:focus {
  border: none;
  outline: none;
}
.footerworkrightbody form button {
  background-color: #771bc8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px 15px;
}
.formline {
  width: 100%;
  border: 1px solid #666;
}
.footerrightmost {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  gap: 10px;
  margin-top: 20px;
}
.footerrightmost ul {
  padding: 20px 10px 0px 10px;
  background-color: #350e59;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.footerrightmost ul li {
  background-color: #8719e8;
  padding: 6px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerrightmost ul li img {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 1000px) {
  .footerwork {
    flex-direction: column;
  }
  .footerworkright {
    flex-direction: column;
  }
  .footerworkleft {
    width: 100%;
  }
  .footerworkrightbody {
    width: 100%;
  }
  .socials {
    justify-content: center;
  }
  .footerrightmost {
    justify-content: center;
  }
  .footerrightmost ul {
    padding: 20px 20px 5px 20px;
    flex-direction: row;
    gap: 20px;
    border-radius: 20px;
  }
}
