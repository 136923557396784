.expertisebody {
  background-color: #080807;
  width: 100vw;
  padding-top: 30px;
}
.expertisebody h1 {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  color: #bfbfb1;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
}
.expertisebody hr {
  width: 80%;
  margin: 0 auto;
  opacity: 0.2;
}

