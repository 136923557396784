.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-up {
  animation-timeline: view();
  animation-range-start: 60vh;
  animation-range-end: 100vh;
  animation-name: slideUp;
  animation-fill-mode: both;
  animation-duration: 100ms;
  animation-timing-function: linear;
}

#testimonial {
  z-index: 10;
  margin-top: -80vh;
}
body.no-animation #testimonial {
  animation: none;
  margin-top: 0vh;
}
