@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  overflow-x: clip;
}
*::-webkit-scrollbar {
  display: none;
}
body {
  background-color: #1b052d;
}
.commonheader {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  color: #bfbfb1;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
}
