.contactmainwhole {
  background: rgb(35, 5, 64);
  background: -moz-linear-gradient(
    0deg,
    rgba(35, 5, 64, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(35, 5, 64, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(35, 5, 64, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#230540",endColorstr="#000000",GradientType=1);
  overflow-y: clip;
}
.contactmain {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 20px;
}
.contactmain h1 {
  font-size: 80px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}
.yellowcolor {
  display: inline;
  color: #ffc100;
}
.contactmain p {
  width: 55%;
}
.contactbuttons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.booknow {
  background-color: #771bc8;
  color: #fff;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.bookservices {
  background: none;
  border: 2px solid #771bc8;
  padding: 10px 20px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  border-radius: 50px;
}
.footertopimg {
  margin-bottom: -5px;
  margin-left: -10px;
}

@keyframes charactershow {
  0% {
    transform: scale(0%);
  }
  100% {
    transform: scale(100%);
  }
}

.contactmain {
  animation-timeline: view();
  animation-range-start: 0vh;
  animation-range-end: 100vh;

  animation-name: charactershow;
  animation-fill-mode: both;
  animation-duration: 100ms;
  animation-timing-function: linear;
}

@media screen and (max-width: 1000px) {
  .contactmain h1 {
    font-size: 30px;
  }
  .contactmain p {
    width: 80%;
  }
  .contactbuttons {
    flex-direction: column;
  }
  .booknow {
    width: 100%;
  }
  .bookservices {
    width: 100%;
  }
  .footertopimg {
    margin-bottom: 0;
  }
}
