.designsbody {
  background-color: #111110;
  padding: 50px 0px;
}
.designsmain {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-inline: 40px;
  overflow: clip;
}

.designleft,
.designcenter,
.designright {
  width: 30%;
  height: 100vh;
  overflow: clip;
  position: relative;
}

.designleft-scroll,
.designcenter-scroll,
.designright-scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  animation-timing-function: linear;
  white-space: nowrap;
}

.designleft-scroll {
  animation: scroll-up 15s linear infinite;
}

.designright-scroll {
  animation: scroll-up 15s linear infinite;
}
.designcenter-scroll {
  animation: scroll-down 15s linear infinite;
}

@keyframes scroll-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100%));
  }
}

@keyframes scroll-down {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.designleft img,
.designcenter img,
.designright img {
  width: 100%;
  margin-bottom: 10px;
}

.designsmobile {
  display: none;
  height: 100vh;
  justify-content: center;
  gap: 40px;
  padding-inline: 10px;
  overflow-y: scroll;
}

@media screen and (max-width: 1000px) {
  .designsmain {
    display: none;
  }
  .designsmobile {
    display: flex;
  }
  .designright,
  .designleft {
    width: 45%;
  }
  .designright-scroll {
    animation: scroll-down 12s linear infinite;
  }
  .designleft-scroll {
    animation: scroll-up 12s linear infinite;
  }
}
