.dropdown {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  padding: 20px;
  background-color: black;
  color: white;
  z-index: 10;
  transition: transform 0.5s ease-in-out;
  transform: translateY(-100%);
}

.dropdown.show {
  transform: translateY(0);
}

.dropdown .navbar {
  height: 10vh;
}

.navrightmobiledrop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 10vh);
}
.navrightmobiledrop li {
  display: flex;
  gap: 20px;
  font-size: 20px;
  padding: 20px;

  width: 100%;
}

@media screen and (min-width: 1000px) {
  .dropdown {
    display: none;
  }
}
