.expertisecomponent {
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}
.leftexpertisebody {
  width: 100%;
  gap: 40px;
  padding-bottom: 40px;
  display: flex;
}
.leftexpertise,
.rightexpertise {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.leftexpertiseheader {
  width: 100%;
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.leftexpertiseheader h3 {
  font-size: 32px;
  font-weight: 500;
  color: #bfbfb1;
  font-family: "Inter", sans-serif;
}
.leftexpertiseheader img {
  width: 15px;
  height: 15px;
}

.rightexpertise {
  position: relative;
  overflow: clip;
}
.rightexpertisetop {
  z-index: 10;
  font-size: 24px;
}
.rightexpertisetop ul li {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  color: #bfbfb1;
}
.rightexpertisebottom {
  position: absolute;
  top: -40px;
  left: calc(80% - 180px);
  width: 100%;
  font-weight: 800;
}
.rightexpertisebottom h5 {
  font-size: 150px;
  color: #771bc8;
  opacity: 0.3;
}

.leftexpertise p {
  font-size: 16px;
  width: 70%;
  font-family: "Inter", sans-serif;
  margin-top: 10px;
  color: #a29e9a;
  line-height: 1.5;
}

@keyframes expertisecontenthide {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0;
    font-size: 0px;
    overflow: clip;
    padding-bottom: 0px;
  }
}
@keyframes expertisecontentshow {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 1000px;
  }
}
.leftexpertisebody {
  animation-timeline: view();
  animation-range-start: 20vh;
  animation-range-end: 80vh;

  animation-name: expertisecontenthide;
  animation-fill-mode: both;
  animation-duration: 100ms;
  animation-timing-function: linear;
}
@media screen and (max-width: 1000px) {
  .rightexpertise {
    display: none;
  }
  .leftexpertise {
    width: 100%;
  }
  .leftexpertise p {
    width: 100%;
  }
  .leftexpertiseheader h3 {
    font-size: 20px;
  }
  .leftexpertisebody {
    width: 100%;
  }
}

body.no-animation .leftexpertisebody {
  animation: none;
}

